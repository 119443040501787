var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "Register"
  }, [_c('BackArrow', {
    attrs: {
      "variant": "white"
    }
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [!_vm.registered ? _c('div', {
    staticClass: "Wrapper CustomerData"
  }, [_c('div', {
    staticClass: "Header"
  }, [_c('h1', [_vm._v("Registrierung")])]), _c('p', [_vm._v(" Bitte füllen Sie das Formular aus, um Ihr Unternehmen zu registrieren. "), _c('br'), _vm._v(" Wir werden uns dann mit Ihnen in Verbindung setzen. ")]), _c('br'), _c('br'), _c('form', {
    staticClass: "CustomerForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c('h3', {
    staticClass: "FormSectionHeading"
  }, [_vm._v("Kontaktdaten")]), _c('div', {
    staticClass: "FormSection"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.contactPerson
    }
  }, [_c('label', {
    attrs: {
      "for": "contactPerson"
    }
  }, [_vm._v("Ansprechpartner")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.contactPerson,
      expression: "form.contactPerson"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "contactPerson"
    },
    domProps: {
      "value": _vm.form.contactPerson
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "contactPerson", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name des Ansprechpartners.")]), _vm.errors.contactPerson ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.contactPerson))]) : _vm._e()]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.email
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "Input",
    attrs: {
      "type": "email",
      "id": "email"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Damit wir Sie kontaktieren können.")]), _vm.errors.email ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.email))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.phoneNumber
    }
  }, [_c('label', {
    attrs: {
      "for": "phoneNumber"
    }
  }, [_vm._v("Telefon")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phoneNumber,
      expression: "form.phoneNumber"
    }],
    staticClass: "Input",
    attrs: {
      "type": "tel",
      "id": "phoneNumber"
    },
    domProps: {
      "value": _vm.form.phoneNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "phoneNumber", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Damit wir Sie kontaktieren können.")]), _vm.errors.phoneNumber ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.phoneNumber))]) : _vm._e()])])]), _c('h3', {
    staticClass: "FormSectionHeading"
  }, [_vm._v("Firmendaten")]), _c('div', {
    staticClass: "FormSection"
  }, [_c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.owner
    }
  }, [_c('label', {
    attrs: {
      "for": "owner"
    }
  }, [_vm._v("Inhaber")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.owner,
      expression: "form.owner"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "owner"
    },
    domProps: {
      "value": _vm.form.owner
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "owner", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name des Inhabers.")]), _vm.errors.owner ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.owner))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.ownerBirthdate
    }
  }, [_c('label', {
    attrs: {
      "for": "ownerBirthdate"
    }
  }, [_vm._v("Geburtsdatum")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ownerBirthdate,
      expression: "form.ownerBirthdate"
    }],
    staticClass: "Input",
    attrs: {
      "type": "date",
      "id": "ownerBirthdate"
    },
    domProps: {
      "value": _vm.form.ownerBirthdate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ownerBirthdate", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Das Geburtsdatum des Inhabers.")]), _vm.errors.ownerBirthdate ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.ownerBirthdate))]) : _vm._e()])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.businessName
    }
  }, [_c('label', {
    attrs: {
      "for": "businessName"
    }
  }, [_vm._v("Firmenname")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.businessName,
      expression: "form.businessName"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "businessName"
    },
    domProps: {
      "value": _vm.form.businessName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "businessName", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der offizielle Name Ihres Unternehmens.")]), _vm.errors.businessName ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.businessName))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.legalForm
    }
  }, [_c('label', {
    attrs: {
      "for": "legalForm"
    }
  }, [_vm._v("Rechtsform")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.legalForm,
      expression: "form.legalForm"
    }],
    staticClass: "Input",
    attrs: {
      "id": "legalForm"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "legalForm", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Einzelunternehmer"
    }
  }, [_vm._v("Einzelunternehmen")]), _c('option', {
    attrs: {
      "value": "GmbH"
    }
  }, [_vm._v("GmbH")]), _c('option', {
    attrs: {
      "value": "UG"
    }
  }, [_vm._v("UG")]), _c('option', {
    attrs: {
      "value": "AG"
    }
  }, [_vm._v("AG")]), _c('option', {
    attrs: {
      "value": "OHG"
    }
  }, [_vm._v("OHG")]), _c('option', {
    attrs: {
      "value": "GbR"
    }
  }, [_vm._v("GbR")])]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Rechtsform Ihres Unternehmens.")]), _vm.errors.legalForm ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.legalForm))]) : _vm._e()])]), _c('div', {
    staticClass: "FormRow"
  }, [_vm.showTaxNumberField ? _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.taxNumber
    }
  }, [_c('label', {
    attrs: {
      "for": "taxNumber"
    }
  }, [_vm._v("Steuernummer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.taxNumber,
      expression: "form.taxNumber"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "taxNumber"
    },
    domProps: {
      "value": _vm.form.taxNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "taxNumber", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint HintWithAction"
  }, [_vm._v("Ihre Steuernummer. "), _c('span', {
    staticClass: "action",
    on: {
      "click": function click($event) {
        _vm.showTaxNumberField = false;
      }
    }
  }, [_vm._v(" Ich habe eine USt-IdNr.")])]), _vm.errors.taxNumber ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.taxNumber))]) : _vm._e()]) : _vm._e(), !_vm.showTaxNumberField ? _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.taxId
    }
  }, [_c('label', {
    attrs: {
      "for": "taxId"
    }
  }, [_vm._v("USt-IdNr.")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.taxId,
      expression: "form.taxId"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "taxId"
    },
    domProps: {
      "value": _vm.form.taxId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "taxId", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint HintWithAction"
  }, [_vm._v("Ihre Umsatzsteuer-Identifikationsnummer. "), _c('span', {
    staticClass: "action",
    on: {
      "click": function click($event) {
        _vm.showTaxNumberField = true;
      }
    }
  }, [_vm._v(" Ich habe keine USt-IdNr.")])]), _vm.errors.taxId ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.taxId))]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.streetOne
    }
  }, [_c('label', {
    attrs: {
      "for": "streetOne"
    }
  }, [_vm._v("Straße")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.streetOne,
      expression: "form.streetOne"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "streetOne"
    },
    domProps: {
      "value": _vm.form.streetOne
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "streetOne", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Straße Ihres Firmensitzes.")]), _vm.errors.streetOne ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.streetOne))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "streetTwo"
    }
  }, [_vm._v("Zusatz")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.streetTwo,
      expression: "form.streetTwo"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "streetTwo"
    },
    domProps: {
      "value": _vm.form.streetTwo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "streetTwo", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Zusätzliche Adressinformationen.")])])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.zipCode
    }
  }, [_c('label', {
    attrs: {
      "for": "zipCode"
    }
  }, [_vm._v("PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.zipCode,
      expression: "form.zipCode"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "zipCode"
    },
    domProps: {
      "value": _vm.form.zipCode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "zipCode", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Postleitzahl Ihres Firmensitzes.")]), _vm.errors.zipCode ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.zipCode))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.city
    }
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v("Ort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "city"
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Ort Ihres Firmensitzes.")]), _vm.errors.city ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.city))]) : _vm._e()])])]), _c('h3', {
    staticClass: "FormSectionHeading"
  }, [_vm._v("Bankdaten")]), _c('div', {
    staticClass: "FormSection"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.hasDirectDebit
    }
  }, [_c('label', {
    attrs: {
      "for": "hasDirectDebit"
    }
  }, [_vm._v(" Lastschrift")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hasDirectDebit,
      expression: "form.hasDirectDebit"
    }],
    staticClass: "Input",
    attrs: {
      "id": "hasDirectDebit"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "hasDirectDebit", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": true
    }
  }, [_vm._v("Ja")]), _c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Nein")])]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Möchten Sie per Lastschrift zahlen?")]), _vm.errors.hasDirectDebit ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.hasDirectDebit))]) : _vm._e()]), _vm.form.hasDirectDebit ? _c('div', {
    staticClass: "FormRow",
    class: {
      'has-error': _vm.errors.iban
    }
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.contactPerson
    }
  }, [_c('label', {
    attrs: {
      "for": "bankAccountOwner"
    }
  }, [_vm._v("Kontoinhaber")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bankAccountOwner,
      expression: "form.bankAccountOwner"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "bankAccountOwner"
    },
    domProps: {
      "value": _vm.form.bankAccountOwner
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bankAccountOwner", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Kontoinhaber")]), _vm.errors.bankAccountOwner ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.bankAccountOwner))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.bank
    }
  }, [_c('label', {
    attrs: {
      "for": "bank"
    }
  }, [_vm._v("Bank")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank,
      expression: "form.bank"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "bank"
    },
    domProps: {
      "value": _vm.form.bank
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name Ihrer Bank.")]), _vm.errors.bank ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.bank))]) : _vm._e()])]) : _vm._e(), _vm.form.hasDirectDebit ? _c('div', {
    staticClass: "FormRow",
    class: {
      'has-error': _vm.errors.iban
    }
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.contactPerson
    }
  }, [_c('label', {
    attrs: {
      "for": "iban"
    }
  }, [_vm._v("IBAN")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.iban,
      expression: "form.iban"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "iban"
    },
    domProps: {
      "value": _vm.form.iban
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "iban", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre IBAN.")]), _vm.errors.iban ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.iban))]) : _vm._e()]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.bic
    }
  }, [_c('label', {
    attrs: {
      "for": "bic"
    }
  }, [_vm._v("BIC")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bic,
      expression: "form.bic"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "bic"
    },
    domProps: {
      "value": _vm.form.bic
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bic", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre BIC.")]), _vm.errors.bic ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.bic))]) : _vm._e()])]) : _vm._e(), _vm.form.hasDirectDebit ? _c('div', {
    staticClass: "InputWrap Row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.sepaConfirmed,
      expression: "form.sepaConfirmed"
    }],
    attrs: {
      "type": "checkbox",
      "id": "sepaConfirmed"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.sepaConfirmed) ? _vm._i(_vm.form.sepaConfirmed, null) > -1 : _vm.form.sepaConfirmed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.sepaConfirmed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "sepaConfirmed", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "sepaConfirmed", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "sepaConfirmed", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "sepaConfirmed"
    }
  }, [_vm._v(" Hiermit ermächtige ich Nailed-IT Solutions UG widerruflich, fällige Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von Nailed-IT Solutions UG auf mein Konto gezogenen Lastschriften einzulösen. "), _c('br'), _c('strong', [_vm._v("Hinweis:")]), _vm._v(" Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. "), _vm.errors.sepaConfirmed ? _c('span', {
    staticClass: "Error"
  }, [_c('br'), _vm._v(_vm._s(_vm.errors.sepaConfirmed))]) : _vm._e()])]) : _vm._e()]), _c('h3', {
    staticClass: "FormSectionHeading"
  }, [_vm._v("Account Erstellung")]), _c('div', {
    staticClass: "FormSection"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "notes"
    }
  }, [_vm._v("Nachricht")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.notes,
      expression: "form.notes"
    }],
    staticClass: "Input",
    attrs: {
      "id": "notes"
    },
    domProps: {
      "value": _vm.form.notes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "notes", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Zusätzliche Nachricht.")])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.passwordErrors.length > 0
    }
  }, [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v("Passwort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "Input",
    attrs: {
      "type": "password",
      "id": "password"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }, _vm.handlePasswordChange]
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Wählen Sie ein sicheres Passwort.")]), _c('ul', {
    staticClass: "PasswordRequirements"
  }, _vm._l(_vm.passwordRequirements, function (requirement, index) {
    return _c('li', {
      key: index
    }, [_c('i', {
      class: requirement.isValid ? 'ri-check-line valid' : !!requirement.isValid ? 'ri-close-line invalid' : 'ri-circle-line neutral'
    }), _vm._v(" " + _vm._s(requirement.text) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.errors.passwordConfirmation
    }
  }, [_c('label', {
    attrs: {
      "for": "passwordConfirmation"
    }
  }, [_vm._v("Passwort bestätigen")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.passwordConfirmation,
      expression: "form.passwordConfirmation"
    }],
    staticClass: "Input",
    attrs: {
      "type": "password",
      "id": "passwordConfirmation"
    },
    domProps: {
      "value": _vm.form.passwordConfirmation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "passwordConfirmation", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Passwort zur Bestätigung noch einmal ein.")]), _vm.errors.passwordConfirmation ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.passwordConfirmation))]) : _vm._e()])]), _c('br'), _c('div', {
    staticClass: "InputWrap Row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.terms,
      expression: "form.terms"
    }],
    attrs: {
      "type": "checkbox",
      "id": "terms"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.terms) ? _vm._i(_vm.form.terms, null) > -1 : _vm.form.terms
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.terms,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "terms", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "terms", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "terms", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "terms"
    }
  }, [_vm._v(" Ich habe die "), _c('router-link', {
    staticClass: "action",
    attrs: {
      "to": "/terms-of-service"
    }
  }, [_vm._v("Allgemeinen Geschäftsbedingungen ")]), _vm._v(" und die "), _c('router-link', {
    staticClass: "action",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Datenschutzerklärung ")]), _vm._v(" gelesen und akzeptiere sie. "), _c('br'), _vm.errors.terms ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.errors.terms))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "FormActions"
  }, [_c('Button', {
    staticClass: "SaveButton",
    attrs: {
      "type": "submit",
      "isLoading": _vm.isLoadingRegistration
    }
  }, [_vm._v(" Registrierung Abschließen ")])], 1)])])]) : _c('div', {
    ref: "registerSuccess",
    staticClass: "FormSection"
  }, [_c('h2', [_vm._v("Registrierung Abeschlossen")]), _c('p', [_vm._v(" Sie wurde erfolgreich registriert. "), _c('br'), _vm._v(" Bitte prüfen Sie ihre E-Mail um Ihre Zugangsdaten zu erhalten. ")])])])], 1), _c('SiteFooter')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }