<template>
    <div class="AppNavigation" id="AppNavigation">
        <nav class="Wrapper">
            <router-link to="/" class="Logo" @click.native="setIndicatorPos">
                <img src="@/assets/svg/logo.svg" alt />
            </router-link>

            <router-link class="Nav-Link" to="/dashboard" @click.native="setIndicatorPos">
                <i class="ri-dashboard-line"></i>
                Dashboard
            </router-link>

            <router-link
                v-if="hasPerformanceAnalyticsModule"
                class="Nav-Link"
                to="/analytics"
                @click.native="setIndicatorPos"
            >
                <i class="ri-bar-chart-line"></i>
                Analyse
            </router-link>

            <router-link
                v-if="hasSchedulerModule"
                class="Nav-Link"
                to="/schedular"
                @click.native="setIndicatorPos"
            >
                <i class="ri-calendar-line"></i>
                Schichtplanner
            </router-link>

            <router-link
                v-if="hasDriverLogsModule"
                class="Nav-Link"
                to="/driversLog"
                @click.native="setIndicatorPos"
            >
                <i class="ri-booklet-line"></i>
                Fahrtenbuch
            </router-link>

            <router-link class="Nav-Link" to="/revenue" @click.native="setIndicatorPos">
                <i class="ri-money-dollar-circle-line"></i>
                Umsatz
            </router-link>

            <router-link class="Nav-Link" to="/working-time" @click.native="setIndicatorPos">
                <i class="ri-time-line"></i>
                Arbeitszeiten
            </router-link>

            <router-link class="Nav-Link" to="/shifts" @click.native="setIndicatorPos">
                <i class="ri-repeat-line"></i>
                Schichten
            </router-link>

            <router-link class="Nav-Link" to="/trips" @click.native="setIndicatorPos">
                <i class="ri-steering-2-line"></i>
                Einzelfahrten
            </router-link>

            <router-link class="Nav-Link" to="/operationride" @click.native="setIndicatorPos">
                <i class="ri-car-line"></i>
                Betriebsnachweise
            </router-link>

            <router-link class="Nav-Link" to="/employees" @click.native="setIndicatorPos">
                <i class="ri-team-line"></i>
                Mitarbeiter
            </router-link>

            <router-link class="Nav-Link" to="/cars" @click.native="setIndicatorPos">
                <i class="ri-car-fill"></i>
                Fahrzeuge
            </router-link>

            <router-link
                v-if="hasSavingsCalculatorModule"
                class="Nav-Link"
                to="/savings-calculator"
                @click.native="setIndicatorPos"
            >
                <i class="ri-calculator-line"></i>
                Rücklagenrechner
            </router-link>

            <router-link class="Nav-Link" to="/invoice" @click.native="setIndicatorPos">
                <i class="ri-file-text-line"></i>
                Rechnungen
            </router-link>

            <router-link class="Nav-Link" to="/settings" @click.native="setIndicatorPos">
                <i class="ri-settings-3-line"></i>
                Einstellungen
            </router-link>

            <div class="BusinessName">
                <span>Unternehmen</span>
                <br />
                <p>{{ businessName }}</p>
            </div>
            <AppFooter />
            <Button :isLoading="isLoggingOut" size="small" to="/" @onClick="handleLogoutClick">
                <i class="ri-logout-box-line" style="margin-right: 10px;"></i>
                <span>Logout</span>
            </Button>

            <span class="Indicator" ref="indicator"></span>
        </nav>
    </div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import anime from 'animejs';
import axios from 'axios';
import AppFooter from '@/components/AppFooter.vue';
import Button from '@/components/widgets/Button.vue';

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

export default {
    name: 'AppNavigation',
    components: { AppFooter, Button },
    data() {
        return {
            isLoggingOut: false,
        };
    },
    watch: {
        $route: {
            handler() {
                this.$nextTick(this.setIndicatorPos);
            },
        },
    },
    computed: {
        ...mapGetters(['businessName', 'modules']),
        hasPerformanceAnalyticsModule() {
            return this.modules.includes('performanceAnalysis');
        },
        hasDriverLogsModule() {
            return this.modules.includes('driverLogs');
        },
        hasReminderModule() {
            return this.modules.includes('reminder');
        },
        hasSchedulerModule() {
            return this.modules.includes('scheduler');
        },
        hasSavingsCalculatorModule() {
            return this.modules.includes('savingsCalculator');
        },
    },
    methods: {
        ...mapActions(['setUser']),

        handleResize: debounce(function() {
            this.setIndicatorPos();
        }, 150),

        async handleLogoutClick(e) {
            this.isLoggingOut = true;
            await axios.get(`${process.env.VUE_APP_API_BASE_URL}/logout`, {
                withCredentials: true,
            });
            // await this.setUser(null);
            setTimeout(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.href = '/';
            }, 200);
        },
        setIndicatorPos() {
            const route = this.$route.path;
            const link = this.$el.querySelector(`a[href="${route}"]`);
            if (!link) return;

            const indicator = this.$refs.indicator;
            const padding = 8; // Adjust padding for positioning
            const isMobile = window.innerWidth <= 768; // Detect mobile view

            if (isMobile) {
                const linkRect = link.getBoundingClientRect();
                const indicatorOldX = indicator?.getBoundingClientRect?.()?.x || 0;
                const indicatorNewX =
                    linkRect.left + linkRect.width / 2 - indicator.offsetWidth / 2;
                const diff = Math.abs(indicatorOldX - indicatorNewX);
                // Responsive scaling for smooth animations
                const scaleMax = Math.max(1.5, 1 + diff / 200);

                requestAnimationFrame(() => {
                    anime({
                        targets: indicator,
                        translateX: [indicatorOldX, indicatorNewX],
                        translateY: '0px',
                        easing: 'spring(1, 80, 13, 0)',
                        scaleX: [
                            { value: scaleMax, delay: 70, duration: 200, easing: 'easeOutQuad' },
                            { value: 1, delay: 0, duration: 300, easing: 'easeOutQuad' },
                        ],
                        transformOrigin: 'center center',
                    });
                });
            } else {
                // Desktop vertical navigation
                const offsetTop = 90; // Top offset for desktop vertical nav
                const indicatorOldY = indicator?.getBoundingClientRect?.()?.y || offsetTop;
                const indicatorNewY = Math.max(link.offsetTop + padding, offsetTop);
                const diff = Math.abs(indicatorOldY - indicatorNewY);
                const scaleMax = Math.max(1.5, 1 + diff / 100);
                const direction = indicatorOldY > indicatorNewY ? 'up' : 'down';

                requestAnimationFrame(() => {
                    anime({
                        targets: indicator,
                        translateY: [indicatorOldY, indicatorNewY],
                        easing: 'spring(1, 80, 13, 0)',
                        scaleY: [
                            { value: scaleMax, delay: 70, duration: 200, easing: 'easeOutQuad' },
                            { value: 1, delay: 0, duration: 200, easing: 'easeOutQuad' },
                        ],
                        transformOrigin: direction === 'up' ? 'top center' : 'bottom center',
                    });
                });
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(this.setIndicatorPos);
        }, 1000);
        window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<style lang="scss">
.theme-dark {
    .AppNavigation {
        background: #000;

        .BusinessName {
            color: #fff;
        }
    }
}

.AppNavigation {
    position: sticky;
    background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    background: var(--color-blue-darker);
    height: 100%;
    grid-area: sidebar;
    overflow: hidden;
    z-index: zIndex(menu);
    top: 0;
    left: 0;
    transition: width 0.4s ease(out-quad);

    @media (max-width: breakpoint(tabletPortrait)) {
        position: fixed;
        bottom: 0;
        left: 0;
        top: unset;
        height: 85px;
        width: 100vw;
        padding-bottom: env(safe-area-inset-bottom);
        z-index: 101;
    }

    .BusinessName {
        color: var(--color-white);
        font-size: 0.75rem;
        margin-top: auto;
        justify-self: flex-end;
        margin-bottom: 0px;
        line-height: 1.5;
        padding: 0 0 10px;

        span {
            font-size: 0.6rem;
        }

        p {
            font-weight: 700;
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            display: none;
        }
    }

    .Logo {
        width: 60px;
        margin: 0 0 20px;

        img {
            width: 100%;
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            display: none;
        }
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .Wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        padding: 20px 30px 20px 20px;
        height: 100%;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: row;
            column-gap: 20px;
            padding: 10px 20px;
            overflow: auto;
        }
    }

    .Indicator {
        position: absolute;
        top: 0;
        left: 0;
        background: color(bg);
        transform: translateY(134px);
        height: 24px;
        width: 10px;
        will-change: auto;
        transform-origin: center center;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            width: 40px;
            height: 5px;
        }
    }

    .Button {
        height: fit-content;
    }

    .Nav-Link {
        font-size: 0.75rem;
        padding: 8px 0 8px;
        display: inline-block;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 700;
        transition: all 0.3s;
        text-transform: uppercase;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        i {
            margin-right: 8px;
            font-size: 1rem;
            font-weight: 400;

            @media screen and (max-width: breakpoint(tabletPortrait)) {
                margin-right: 0px;
            }
        }

        &:hover,
        &.router-link-exact-active {
            color: rgba(255, 255, 255, 1);
        }

        &.CTA {
            padding: 15px 0;

            span {
                display: block;
                padding: 5px 18px 3px;
                background-color: color(yellow-dark);
                color: var(--color-text-gray-dark);
            }
        }
    }
}
</style>
