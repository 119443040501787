<template>
    <div class="Card" :class="[`is-${size}`, `is-${variant}`]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        variant: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
.Card {
    background: var(--color-white);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 50px;
    border-radius: 5px;
    border: 1px solid var(--color-border);

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        padding: 20px;
    }

    &.is-info,
    &.is-warning {
        display: flex;
        flex-direction: column;
        padding: 10px;
        position: relative;

        i {
            font-size: 1rem;
            cursor: pointer;
        }

        h2 {
            font-size: 1.35rem;
            font-weight: 700;
        }

        h3 {
            font-size: 1.35rem;
            font-weight: 700;
        }

        p {
            font-size: 0.75rem;
        }

        button {
            .Label {
                font-size: 0.75rem;
            }
        }
    }

    &.is-warning {
        border: 1px solid var(--color-red);
        color: var(--color-red);

        h3 {
            color: var(--color-red);
        }
    }

    &.is-small {
        padding: 20px 30px;
    }
}
</style>
